<template>
    <div class="login flex-box">
        <div class="login-title flex-box align-center">
            <h3>杭统云E家后台管理系统</h3>
            <div class="sep"></div>
            <h3>用户登录</h3>
        </div>
        <div class="login-box">
            <a-radio-group v-model="type" size="large" button-style="solid" style="width: 100%;">
                <a-radio-button style="width: 50%; text-align: center;" value="account">账号密码登陆</a-radio-button>
                <a-radio-button style="width: 50%; text-align: center;" value="tel">手机验证码登陆</a-radio-button>
            </a-radio-group>
            <a-form-model
                    class="login-form"
                    ref="loginForm"
                    :model="form"
                    :rules="rules"
                    :label-col="{span: 0}"
                    :wrapper-col="{span: 25}">
                <template v-if="type == 'account'">
                    <a-form-model-item label="" prop="username">
                        <a-input size="large" v-model="form.username" placeholder="账号/手机号" @keyup.enter="login">
                            <a-icon slot="prefix" type="user"/>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="" prop="password">
                        <a-input size="large" type="password" v-model="form.password" placeholder="密码" @keyup.enter="login">
                            <a-icon slot="prefix" type="lock" />
                        </a-input>
                    </a-form-model-item>
                </template>
                <template v-else>
                    <a-form-model-item label="" prop="tel">
                        <a-input size="large" v-model="form.tel" placeholder="账号/手机号" @keyup.enter="login">
                            <a-icon slot="prefix" type="user"/>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="" prop="code" >
                        <a-input size="large" v-model="form.code" placeholder="验证码" @keyup.enter="login">
                            <a-icon slot="prefix" type="lock" />
                            <div slot="addonAfter" class="get-verify-btn" @click="getVerifyCode">{{ second > 0 ? `重新获取(${second}s)` : '获取验证码'}}</div>
                        </a-input>
                    </a-form-model-item>
                </template>
            </a-form-model>
            <a-button size="large" type="primary" block @click="login">登录</a-button>
            <div class="login-loading" v-show="loading">
                <a-spin tip="正在登陆...">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
            </div>
        </div>
    </div>
</template>

<script>
    import {setToken, cleanToken} from "../common/js/storage";

    export default {
        name: "Login",
        data() {
            return {
                form: {},
                rules: {
                    username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                    tel: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
                    ],
                    code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
                },
                type: localStorage.getItem("login_type") || "account",
                loading: false,
                img: null,
                token: null,
                second: 0
            }
        },
        created() {
            cleanToken();
            this.$store.commit("setLogin", false);
        },
        methods: {
            getVerifyCode() {
                if(this.sending) return;
                this.$refs.loginForm.validateField('tel', error => {
                    if(!error) {
                        this.sending = true;
                        this.$axios({
                            url: "/login/get-verify-code",
                            method: 'POST',
                            data: {
                                tel: this.form.tel
                            }
                        }).then(res => {
                            if(res.code == 0) {
                                this.$message.success('验证码已发送');
                                this.second = 60;
                                let timer = setInterval(() => {
                                    if(this.second > 0){
                                        this.second --;
                                    } else {
                                        clearInterval(timer);
                                        this.second = 0; //重置时间
                                    }
                                }, 1000);
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }).catch((err) => {
                            console.error(err);
                        }).finally(() => {
                            this.sending = false;
                        })
                    }
                });
            },
            login() {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        const form = this.form;
                        const type = this.type;
                        this.loading = true;
                        let url = '/login/admin-auth', data = {
                            tel: form.username,
                            password: form.password
                        }
                        if(type == 'tel') {
                            url = '/login/auth';
                            data = {
                                tel: form.tel,
                                code: form.code
                            }
                        }
                        this.$axios({
                            url,
                            method: "POST",
                            data
                        }).then(res => {
                            this.loading = false;
                            let code = res.code;
                            if (code == 0) {
                                this.$message.success('登录成功');
                                setToken(res);
                                // 账号密码登陆且 需要修改密码
                                if(type == 'account' && (res.need_alter_pwd || res.pwd_long_time_no_change)) {
                                    this.$router.replace({
                                        name: 'Password',
                                        query: Object.assign(this.$route.query, {
                                            type: res.need_alter_pwd ? 'simple' : 'long'
                                        })
                                    });
                                } else {
                                    let path = this.$route.query.r || '/approve';
                                    console.log(path);
                                    this.$router.push(path);
                                }
                                this.$store.commit("setLogin", true);
                                localStorage.setItem("login_type", type); // 缓存登陆方式
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(() => {
                            this.loading = false;
                            // this.$message.error("您的账号密码不匹配");
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .login {
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @background-color-light;
    }
    .login-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 64px;
        padding: 0 @padding-lg;
        h3 {
            margin: 0;
            line-height: 1;
        }
    }
    .sep {
        margin: 0 12px;
        width: 1px;
        height: 16px;
        background-color: @text-color-secondary;
    }
    .login-box {
        position: relative;
        margin-top: 200px;
        padding: @padding-lg;
        background-color: @body-background;
        box-shadow: @box-shadow-base;
        border-radius: 4px;
    }
    .login-box-ti {
        font-size: 20px;
        text-align: center;
    }
    .login-form {
        margin-top: 24px;
        width: 300px;
    }
    .code-img-box {
        margin-right: -12px;
        position: relative;
        width: 76px;
        height: 38px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
        font-size: 0;
        text-align: center;
    }
    .code-img {
        width: 100%;
        height: 100%;
    }
    .login-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,.6);
        cursor: pointer;
    }
    .get-verify-btn {
        margin: 0 -11px;
        padding: 0 10px;
        width: 110px;
        height: 38px;
        line-height: 38px;
        cursor: pointer;
    }
</style>
